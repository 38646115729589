<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="所属部门" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-tree-select
          v-decorator="['department.id', { rules: [{ required: true, message: '请选择所属部门' }] }]"
          allowClear
          :treeData="departmentList"
          placeholder="请选择所属部门"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="工艺名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['name', { rules: [{ required: true, message: '请输入工艺名称' }] }]"
          placeholder="请输入工艺名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="显示名" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['displayName', { rules: [{ required: true, message: '请输入工艺显示名称' }] }]"
          placeholder="请输入工艺显示名称"
        ></a-input>
      </a-form-item>
      <a-form-item label="工艺代码" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="['code', { rules: [{ required: true, message: '请输入工艺代码' }] }]"
          placeholder="请输入工艺代码"
        ></a-input>
      </a-form-item>
      <a-form-item label="监测点" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input-search placeholder="请选择监测点"   v-decorator="['monitoringPoint']"  @search="clickMonitoringPoint">
          <a-button type="primary" slot="enterButton">
            <a-icon type="plus"/>
          </a-button>
        </a-input-search>
      </a-form-item>
      <a-form-item label="备注" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-textarea
          v-decorator="['remarks']"
          placeholder="请输入备注"
          :autoSize="{ minRows: 3 }"
        ></a-textarea>
      </a-form-item>
    </a-form>
    <a-modal
      :footer="null"
      :destroyOnClose="true"
      title="监测点"
      :visible="visible"
      @cancel="handCancel"
    >
      <monitoringPointTree @selectedTreeNode="selectedTreeNode"></monitoringPointTree>
    </a-modal>
  </a-spin>
</template>

<script>
  import configVO from './common/configVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'
  import monitoringPointTree from '../../../components/monitoringPointTree/List'

  export default {
    name: 'Form',
    components: { monitoringPointTree },
    mixins: [entityCURDCallback],
    props: {
      departmentList: {
        type: Array
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.technologyInfo,
        initFormItem: configVO(),
        showFormItem: false,
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        treeData: [],
        node: {
          title: '',
          key: ''
        },
        visible: false,
        selectData: null
      }
    },
    created () {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          'department.id': this.currentDeptId().organizationId + ''
        })
      })
    },
    methods: {
      // 把form取到的值 赋给创建的formItem
      setVOFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.instanceId = this.selectData.key
      },
      // 给form赋值
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          'department.id': values.department.id,
          'name': values.name,
          'displayName': values.displayName,
          'code': values.code,
          'remarks': values.remarks,
          'monitoringPoint': values.instanceName
        })
        this.selectData = {
          title: this.formItem.instanceName,
          key: this.formItem.instanceId
        }
      },
      clickMonitoringPoint () {
        this.visible = true
      },
      handCancel () {
        this.visible = false
      },
      selectedTreeNode (key, node) {
        this.visible = false
        this.selectData = node.dataRef
        this.form.setFieldsValue({
          'monitoringPoint': this.selectData.title,
        })
      }
    }
  }
</script>

<style scoped></style>
