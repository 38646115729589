const configVO = () => {
  return {
    id: '',
    department: {
      id: '',
    },
    name: '',
    displayName: '',
    code: '',
    remarks: '',
    new: true,
    instanceId: ''
  }
}

export default configVO
