<template>
  <a-spin :spinning="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="24"><span>工艺名称：</span>{{ formItem.name || '-' }}</a-col>
        <a-col :span="24"><span>显示名称：</span>{{ formItem.displayName || '-' }}</a-col>
        <a-col :span="24"><span>工艺代码：</span>{{ formItem.code || '-' }}</a-col>
        <a-col :span="24"><span>所属部门：</span>{{ formItem.department.name || '-' }}</a-col>
        <a-col :span="24"><span>监测点：</span>
          {{ formItem.instanceName  || '-' }}
        </a-col>
        <a-col :span="24">
          <a-row>
            <a-col :span="5" style="text-align: right">
              备注：
            </a-col>
            <a-col :span="19">
              {{ formItem.remarks || '-' }}
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
  import configVO from './common/configVO'
  import SERVICE_URLS from '@/api/service.url'
  import entityCURDCallback from '@/views/common/mixins/entityCURDCallback'

  export default {
    name: 'Form',
    components: {},
    mixins: [entityCURDCallback],
    props: {
      technologyList: {
        type: Array
      }
    },
    watch: {
      selectData (val) {
        this.selectData = val
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.technologyInfo,
        initFormItem: configVO(),
        showFormItem: false,
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        equipmentModal: false,
        facilityModal: false,
        selectEquipmentList: [],
        selectFacilityList: []
      }
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.technologyInfo.view,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      }
    }
  }
</script>

<style scoped></style>
