export const configColums = () => {
  return [
    {
      title: '序号',
      width: '60px',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '工艺名称',
      dataIndex: 'name',
      scopedSlots: { customRender: 'name' },
      align: 'center',
    },
    {
      title: '显示名',
      dataIndex: 'displayName',
      scopedSlots: { customRender: 'displayName' },
      align: 'center',
    },
    {
      title: '工艺代码',
      dataIndex: 'code',
      scopedSlots: { customRender: 'code' },
      align: 'center',
    },
    {
      title: '所属部门',
      dataIndex: 'department.name',
      align: 'center',
    },
    // {
    //   title: '类型',
    //   dataIndex: 'systemModule',
    //   scopedSlots: { customRender: 'systemModule' },
    //   align: 'center',
    // },
    {
      title: '监测点名称',
      dataIndex: 'instanceName',
      align: 'center',
    },
    {
      title: '备注',
      ellipsis: true,
      dataIndex: 'remarks',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' },
  ]
}
